let callback = null;
let callbackReconnected = null;
let callbackConnectionLost = null;
let callbackLocalConnectionResumed = null;

const onLocalParticipantReconnecting = (cb) => {
    if(cb instanceof Function) {
        callback = cb;
    }
};

const onLocalParticipantReconnected = (cb) => {
    if(cb instanceof Function) {
        callbackReconnected = cb;
    }
};

const onLocalConnectionLost = (cb) => {
    if(cb instanceof Function) {
        callbackConnectionLost = cb;
    }
};

const onLocalConnectionResumed = (cb) => {
    if(cb instanceof Function) {
        callbackLocalConnectionResumed = cb;
    }
};

export const onLocalParticipantReconnectingListener = () => {
    if(callback) {
        callback();
    }
};

export const onLocalParticipantReconnectedListener = () => {
    if(callbackReconnected) {
        callbackReconnected();
    }
};

export const onLocalConnectionLostListener = () => {
    if(callbackConnectionLost) {
        callbackConnectionLost();
    }
};

export const onLocalConnectionResumedListener = () => {
    if(callbackLocalConnectionResumed) {
        callbackLocalConnectionResumed();
    }
};

export { onLocalParticipantReconnecting, onLocalParticipantReconnected, onLocalConnectionLost, onLocalConnectionResumed };
