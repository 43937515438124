/**
 * @module connect
 */
import p from '../../../package.json';
import validateName from '../../helpers/validateName';
import {openMediaDevices} from '../../helpers/openMediaDevices';
import Session from '../../entities/Session/Session';
import WTSession from '../../entities/WTSession/WTSession';
import {onConnectedListener} from '../SessionListeners/onConnected';
import {onLocalStreamCreatedListener} from '../SessionListeners/onLocalStreamCreated';
import {onSessionErrorListener} from '../ErrorListeners/sessionErrors';
import participantType from '../../constants/participantType';
import {getAudioConstraint, getVideoConstraint} from '../MediaDevices/doPreview';
import {getDisplayMedia} from '../../helpers/getDisplayMedia';

/** Init session
 * @param {String } streamingToken JWT token
 * @param {String } participantName Receive user name with form
 * @param {Object} userConstraints - {audio: Boolean, video: Boolean, videoCodecs: Object}
 * @param {Boolean} withPublish -
 * @param {Object} metadata -
 * @param incomingStream
 * @returns {Function} Local stream. *
 */
const connectBase = async (
                           streamingToken,
                           participantName,
                           userConstraints,
                           withPublish = true,
                           metadata = {},
                           incomingStream
                           ) => {
    try {
        console.log('Sceenic SDK version: ', p.version);

        if(streamingToken.split('.').length !== 3) {
            console.warn('Streaming token string is not valid !');
            throw new Error('Invalid token');
        }

        const data = JSON.parse(atob(streamingToken.split('.')[1]));

        if(data.exp * 1000 < Date.now()){
            console.warn('Streaming token is expired !');
            throw new Error('Token is expired!');
        }
        const userName = participantName ? validateName(participantName) : '';

        const wsUrl = await getWsUrl({ dataFromToken: data, streamingToken });

        const constraints = {
            audio: getAudioConstraint() || (userConstraints.hasOwnProperty('audio') ? userConstraints.audio : true),
            video: getVideoConstraint() || (userConstraints.hasOwnProperty('video') ? userConstraints.video : true),
            videoCodecs: (userConstraints.hasOwnProperty('videoCodecs') ? userConstraints.videoCodecs : {}),
        };

        const isViewer = !constraints.audio && !constraints.video;
        const participantTypeValue = participantType[metadata.participantType] || participantType.FULL_PARTICIPANT;
        if(metadata.participantType === participantType.MESSAGE_ONLY) {
            //fake participant type. remove it to prevent error on server-side
            delete metadata.participantType;
        }
        const session = new Session(wsUrl, {audio: !!constraints.audio, video: !!constraints.video, videoCodecs: constraints.videoCodecs}, participantTypeValue);

        const userAgent = {
            sdk: p.version,
            agent: navigator.userAgent,
            platform: navigator.platform,
            type: 'Web'
        };

        const joinParams = {
            user: userName,
            ...metadata
        };

        if (!isViewer) {
            const stream = incomingStream || await openMediaDevices(constraints);
            session.stream = stream;
            WTSession.setLocalStream(stream);
        } else {
            joinParams.participantType = participantType.VIEWER;
            withPublish = false;
        }

        WTSession.setSession(session);

        session.joinParams = joinParams;

        const participants = await session.connect(joinParams, withPublish, userAgent);

        onConnectedListener(participants);
    } catch (e) {
        onSessionErrorListener(e);
    }
};

const getWsUrl = async ({dataFromToken, streamingToken}) => {
    return new Promise((resolve, reject) => {
        if(dataFromToken.grout) {
           fetch(dataFromToken.grout, {
                method: 'POST',
                headers: {
                    authorization: 'Bearer ' + streamingToken,
                    'Content-Type': 'application/json',
                }
            }).then(data => {
                data.json().then(result => {
                    resolve(`${result.url}/room?token=${streamingToken}`);
                });
            });
        } else {
           resolve(`wss://${dataFromToken.sigress}/room?token=${streamingToken}`);
        }
    });
};

const connect = async (streamingToken, participantName, userConstraints = {audio: true, video: true}, metadata) => {

    await connectBase(streamingToken, participantName, userConstraints, true, metadata);
};

const connectWithoutPublish = async (streamingToken, participantName, userConstraints = {audio: true, video: true}) => {
    await connectBase(streamingToken, participantName, userConstraints, false);
    const session = WTSession.getSession();

    onLocalStreamCreatedListener({
        participantId: session.localParticipant.participantId,
        participantName: session.localParticipant.participantName,
        type: session.localParticipant.type,
        stream: session.stream,
        settings: session.localParticipant.settings,
        isBroadcaster: session.localParticipant.isBroadcaster,
        isViewer: session.localParticipant.isViewer,
        isCelebrity: session.localParticipant.isCelebrity,
        local: true
    });
};

const connectAsAViewer = async (streamingToken, participantName) => {
    await connectBase(streamingToken, participantName, {audio: false, video: false}, false);
};

const connectWithSharingScreen = async (streamingToken, participantName) => {
    const stream = await getDisplayMedia();
    await connectBase(streamingToken, participantName, {audio: true, video: true}, true, {}, stream);
};

export {
    connect,
    connectWithoutPublish,
    connectAsAViewer,
    connectWithSharingScreen
};
