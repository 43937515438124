const callbackSpeakingQueue = [];
const callbackStopSpeakingQueue = [];

/**
 * @module detectSpeach
 */

/**
 *
 * @param cb
 */
const onParticipantStartSpeaking = (cb) => {
  if(cb instanceof Function) {
    callbackSpeakingQueue.push(cb);
  }
};
/**
 *
 * @param cb
 */
const onParticipantStopSpeaking = (cb) => {
  if(cb instanceof Function) {
    callbackStopSpeakingQueue.push(cb);
  }
};
/**
 *
 * @param participantId
 */
export const onParticipantSpeakingListener = (participantId) => {
  if(callbackSpeakingQueue.length) {
    callbackSpeakingQueue.forEach(cb => cb(participantId));
  }
};
/**
 *
 * @param participantId
 */
export const onParticipantStopSpeakingListener = (participantId) => {
  if(callbackStopSpeakingQueue.length) {
    callbackStopSpeakingQueue.forEach(cb => cb(participantId));
  }
};

export {onParticipantStartSpeaking, onParticipantStopSpeaking};
