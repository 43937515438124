let onParticipantInfoUpdatedCB = null;

const onParticipantInfoUpdated = (cb) => {
    if(cb instanceof Function) {
        onParticipantInfoUpdatedCB = cb;
    }
};

export const onParticipantInfoUpdatedListener = (params) => {
    if(onParticipantInfoUpdatedCB) {
        onParticipantInfoUpdatedCB(params);
    }
};

export { onParticipantInfoUpdated };


