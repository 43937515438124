import RpcBuilder from '../../utils/jsonrpc';
import WTSession from '../../entities/WTSession/WTSession';
import {
	onLocalParticipantReconnectedListener,
	onLocalParticipantReconnectingListener
} from '../../api/ReconnectListener/localParticipant';
import { onParticipantRejectedListener } from '../../api/ParticipantListeners/onParticipantRejected';
import { disconnect } from '../../api/Session/disconnect';

class JsonRpcClient {
	constructor(wsUrl, onConnected) {
		if(JsonRpcClient.instance) {
			return JsonRpcClient.instance;
		}

		JsonRpcClient.instance = this;
		this.config = {
			heartbeat: 10000,
			sendCloseMessage: false,
			ws: {
				uri: wsUrl,
				useSockJS: false,
				onconnected: onConnected,
				ondisconnect: this.disconnectCallback,
				onreconnecting: this.reconnectingCallback,
				onreconnected: this.reconnectedCallback,
			},
			rpc: {
				requestTimeout: 20000,
				//notifications
				newParticipant: this.onParticipantJoined,
				participantStartedMediaStream: this.onParticipantPublished,
				incomingConnection: this.processIncomingConnection,
				participantUnpublished: this.onParticipantLeft,
				participantLeft: this.onParticipantLeft,
				participantInfoUpdated: this.participantInfoUpdated,
				participantUnsubscribedFromMediaStream: () => {}, //review later
				newNotification: this.onNewNotification,
				iceCandidate: this.iceCandidateEvent,
				participantUpdatedSettings: this.onParticipantUpdateSettings,
				participantSubscribedOnMediaStream: this.onParticipantSubscribedOnMediaStream,
				switchMode: this.processSwitchMode,
				synchronizerChanged: this.onSynchronizerChanged,
				syncData: this.onSyncData,
				kicked: this.onKicked,
			},
		};

		this.jsonRpcClient = new RpcBuilder.clients(this.config);
	}

	sendRequest = (method, params, callback) => {
		if (params && params instanceof Function) {
			callback = params;
			params = {};
		}

		console.debug(`Sending request: {method: ${method}, params: ${JSON.stringify(params)}}`);
		this.jsonRpcClient.send(method, params, callback);
	};

	close = () => {
		this.jsonRpcClient.close();
		delete JsonRpcClient.instance;
	}

	sendCustomRequest = (params, callback) => {
		this.sendRequest('customRequest', params, callback);
	};

	sendMessage = (room, user, message) => {
		this.sendRequest(
			'sendMessage',
			{ message: message, userMessage: user, roomMessage: room },
			(error, response) => {
				if (error) console.error(error);
			}
		);
	};

	sendNotification = (notification) => {
		this.sendRequest('sendNotification', {
			notification
		}, (error) => {
			if(error) {
				console.log(error);
			}
		});
	};

	kickParticipant = ({participantId, message}) => {
		this.sendRequest('kick', {participantId, message}, (error) => {
			if(error) {
				console.log(error);
			}
		});
	}

	onKicked = ({participant, message}) => {
		onParticipantRejectedListener({participant, message});
		disconnect();
	}

	disconnectCallback = () => {
		// console.log('Websocket connection lost');
		// if (this.isRoomAvailable()) {
		// 	ServiceRoom.getServer().getRoom().onLostConnection();
		// } else {
		// 	console.error('Connection error. Please reload page.');
		// }
	};

	reconnectingCallback = () => {
		if (WTSession.getSession()) {
			onLocalParticipantReconnectingListener();
		}
	};

	reconnectedCallback = () => {
		if (WTSession.getSession()) {
			onLocalParticipantReconnectedListener();
			WTSession.getSession().processReconnect();
		}
	};

	onParticipantJoined = (params) => {
		if (WTSession.getSession()) {
			WTSession.getSession().onParticipantJoined(params);
		}
	};

	onParticipantPublished = (params) => {
		if (WTSession.getSession()) {
			WTSession.getSession().onParticipantPublished(params);
		}
	};

	processIncomingConnection = (params) => {
		if (WTSession.getSession()) {
			WTSession.getSession().onIncomingConnection(params);
		}
	}

	processSwitchMode = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().onSwitchMode(params);
		}
	}

	onSynchronizerChanged = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().onSynchronizerChanged(params);
		}
	}

	onParticipantUpdateSettings = (params) => {

		if(WTSession.getSession()) {
			WTSession.getSession().onParticipantUpdateSettings(params);
		}
	};

	onParticipantLeft = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().onParticipantLeft(params.participantId, params.connectionLost);
		}
	};

	participantInfoUpdated = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().participantInfoUpdated(params);
		}
	};

	onNewNotification = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().onNewMessage(params);
		}
	};

	iceCandidateEvent = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().onIceCandidate(params);
		}
	};

	onSyncData = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().onSyncData(params);
		}
	}

	onParticipantSubscribedOnMediaStream = (params) => {
		if(WTSession.getSession()) {
			WTSession.getSession().onParticipantSubscribedOnMediaStream(params);
		}
	}
}

export default JsonRpcClient;
