/**
 * @module onNewMessage
 */
let onMessageReceivedCB = null;
let onPlayerDataReceivedCB = null;
let onSyncDataCB = null;

/**
 *
 * @param cb
 */
const onMessageReceived = (cb) => {
    onMessageReceivedCB = cb;
};

/**
 *
 * @param cb
 */
const onPlayerData = (cb) => {
    onPlayerDataReceivedCB = cb;
};

const onSyncData = (cb) => {
    if(cb instanceof Function) {
        onSyncDataCB = cb;
    }
};

/**
 *
 * @param message
 * @param participantId
 */
export const onNewMessageListener = (message, participantId) => {
    if(onMessageReceivedCB) {
        onMessageReceivedCB({message, participantId});
    }
};

/**
 *
 * @param player
 */
export const onPlayerDataListener = (player) => {
    if(onPlayerDataReceivedCB) {
        onPlayerDataReceivedCB(player);
    }
};

export const onSyncDataListener = ({data, participantId}) => {
    if(onSyncDataCB) {
        onSyncDataCB({data, participantId});
    }
};

export {
    onMessageReceived,
    onPlayerData,
    onSyncData
};
