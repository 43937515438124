/**
 * @module onOrganizeReceived
 */
const organizerCBQueue = [];
/**
 *
 * @param cb
 */
const onOrganizerReceived = (cb) => {
    if(cb instanceof Function) {
        organizerCBQueue.push(cb);
    }
};
/**
 *
 */
export const onOrganizerReceivedListener = () => {
    if(organizerCBQueue.length) {
        organizerCBQueue.forEach(cb => cb());
    }
};

export default onOrganizerReceived;
