/* eslint-disable */
import React, { useEffect, useRef, useMemo } from 'react'
import WT from '@sscale/wtsdk'
import './Video.css'

function Video ({ participant }) {
  const imageVideoRef = useRef(null)
  const imageAudioRef = useRef(null)

  useEffect(() => {
    WT.ParticipantListeners.onParticipantMediaStreamChanged((participantId, type, state) => {
      if (participantId === participant.participantId) {
        if (type === 'VIDEO') {
          if (state === 'DISABLED') {
            imageVideoRef.current.src = process.env.PUBLIC_URL + '/camera-off.svg'
          } else {
            imageVideoRef.current.src = process.env.PUBLIC_URL + '/camera.svg'
          }
        } else if (type === 'AUDIO') {
          if (state === 'DISABLED') {
            imageAudioRef.current.src = process.env.PUBLIC_URL + '/mic-off.svg'
          } else {
            imageAudioRef.current.src = process.env.PUBLIC_URL + '/mic.svg'
          }
        }
      }
    })

    WT.ParticipantListeners.onParticipantSpeaking((participantId) => {
      if (participantId === participant.participantId) {
        const elem = document.getElementById(`video-container-${participant.participantId}`)

        if (elem) {
          elem.classList.add('active-speaker')
        }
      }
    })

    WT.ParticipantListeners.onParticipantStopSpeaking((participantId) => {
      if (participantId === participant.participantId) {
        const elem = document.getElementById(`video-container-${participant.participantId}`)

        if (elem) {
          elem.classList.remove('active-speaker')
        }
      }
    })
  }, [participant.participantId])

  const memoizedVideo = useMemo(() => {
    return (
      <video
        className='participant-video'
        id={'participant-' + participant.participantId}
        muted={true}
        playsInline
        pip={"true"}
        autoPlay
        disablePictureInPicture
        ref={(r) => {
          if (r) {
            r.srcObject = participant.stream
          }
        }}
      >
        <source src={participant.stream} type="video/webm" />
      </video>
    )
  }, [participant.participantId, participant.stream])

  if (!participant.stream) {
    return (<></>)
  }

  return (
    <div id={`video-container-${participant.participantId}`} className='video-container' key={participant.participantId}>
      {memoizedVideo}
      <div className='control-video-block'>

        <div className='participant-name'>
          <div>{participant.participantName}</div>
        </div>
      </div>
    </div>
  )
}

export default Video
