import WTSession from '../../entities/WTSession/WTSession';

/**
 * @module remoteMedia
 */

const getParticipant = (participantId) => {
    const session = WTSession.getSession();

    if(session) {
        return session.participants.find(p => p.participantId === participantId);
    }

    return null;
};

const toggleRemoteAudio = (participantId) => {
    const participant = getParticipant(participantId);

    if(participant && participant.stream) {
        const tracks = participant.stream.getTracks();
        const audioTrack = tracks.find(track => track.kind === 'audio');

        if(audioTrack) {
            audioTrack.enabled = !audioTrack.enabled;

            return audioTrack.enabled;
        }
    }
};

const toggleRemoteVideo = (participantId) => {
    const participant = getParticipant(participantId);

    if(participant && participant.stream) {
        const tracks = participant.stream.getTracks();
        const videoTrack = tracks.find(track => track.kind === 'video');
        if(videoTrack) {
            videoTrack.enabled = !videoTrack.enabled;

            return videoTrack.enabled;
        }
    }
};

const isRemoteTrackEnabled = (participantId, kind) => {
    const participant = getParticipant(participantId);

    if(participant && participant.stream) {
        const tracks = participant.stream.getTracks();
        const track = tracks.find(track => track.kind === kind);

        return track.enabled;
    }
};

export default {
    /**
     * @method toggleRemoteAudio
     */
    toggleRemoteAudio,
    /**
     * @method toggleRemoteVideo
     */
    toggleRemoteVideo,
    /**
     *
     * @param participantId
     * @returns {boolean | undefined}
     */
    isRemoteAudioEnabled: (participantId) => isRemoteTrackEnabled(participantId, 'audio'),
    /**
     *
     * @param participantId
     * @returns {boolean | undefined}
     */
    isRemoteVideoEnabled: (participantId) => isRemoteTrackEnabled(participantId, 'video'),
};

