import {MediaException} from '../api/ErrorListeners/Exceptions';

export const getDisplayMedia = async (audio = true) => {
    const displayMediaOptions = {
        video: {
            cursor: 'always'
        },
    };

    try{
        await navigator.mediaDevices.getUserMedia({audio: true, video: false});
    }catch(error){
        throw new MediaException('USER_AUDIO_PERMISSION_DENIED_ERROR_CODE', 'Could not get audio permission!');
    }


    try {
        const audioStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
        const audio = audioStream.getAudioTracks()[0];
        const screenStream =  await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
        screenStream.addTrack(audio);
        return screenStream;
    } catch (e) {
        throw new MediaException('USER_VIDEO_PERMISSION_DENIED_ERROR_CODE', 'Could not get video permission!');

    }
};
