import adapter from 'webrtc-adapter';
global.BrowserDetails = adapter.browserDetails;

import Session from './src/api/Session';
import Participant from './src/api/Participant';
import SessionListeners from './src/api/SessionListeners';
import ParticipantListeners from './src/api/ParticipantListeners';
import ErrorsListeners from './src/api/ErrorListeners';
import {getMediaDevices, onDeviceListUpdates} from './src/api/MediaDevices/GetMediaDevices';
import ChangeMediaDevices from './src/api/MediaDevices/ChangeMediaDevices';
import {
	doPreview, getAudioConstraint, getVideoConstraint,
	setAudioConstraints,
	setVideoConstraints,
} from './src/api/MediaDevices/doPreview';
import ReconnectListeners from './src/api/ReconnectListener';

export default {
	Session,
	Participant,
	SessionListeners,
	ParticipantListeners,
	ErrorsListeners,
	ReconnectListeners,
	getMediaDevices,
	onDeviceListUpdates,
	ChangeMediaDevices,
	doPreview,
	getVideoConstraint,
	getAudioConstraint,
	setAudioConstraints,
	setVideoConstraints
};
