/**
 * @module onParticipantRejected
 */
let callback  = null;
/**
 *
 * @param cb
 */
export const onParticipantRejected = (cb) => {
    callback = cb;
};

export const onParticipantRejectedListener = ({participant, message}) => {
    if(callback) {
        callback(participant, message);
    }
};
