import WTSession from '../../entities/WTSession/WTSession';

/**
 * @module setMediaQuality
 */

/**
 * @method setMediaQuality
 * @param videoHeight
 * @param videoWidth
 * @param frameRate
 */
const setMediaQuality = ({videoHeight, videoWidth, frameRate}) => {
    const stream = WTSession.getLocalStream();
    const { localParticipant } = WTSession.getSession();

    if (stream) {
        const videoTrack = stream.getTracks().find(track => track.kind === 'video');

        if (videoTrack) {
            const constraints = {};

            if (videoHeight) {
                constraints.height = videoHeight;
            }

            if (videoWidth) {
                constraints.width = videoWidth;
            }

            if (frameRate) {
                constraints.frameRate = frameRate;
            }

            videoTrack.applyConstraints({...constraints});
            localParticipant.updateQualityConfig({...constraints});
        }

    }
};

export default setMediaQuality;
