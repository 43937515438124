import WTSession from '../../entities/WTSession/WTSession';
import { SERVICE_MODES } from '../../constants/modes';

const changeMediaDevice = async (deviceId, type, selector) => {
    const session = WTSession.getSession();
    if (!deviceId || typeof deviceId !== 'string') {
        console.error('Can not change media device, deviceId should be a string');
        return;
    }

  if (!document.getElementById(selector) || !['VIDEO', 'AUDIO'].includes(document.getElementById(selector).tagName)) {
    console.error('Invalid selector');
    return;
  }

    if (type === 'camera') {
        await changeVideo(deviceId, selector);
        session.localParticipant.devices = { ...session.localParticipant.devices, camera: {
            deviceId, selector
            }
        };
    }

    if (type === 'microphone') {
        await changeMicro(deviceId, selector);
        session.localParticipant.devices = { ...session.localParticipant.devices, microphone: {
                deviceId, selector
            }
        };
    }

  if (type === 'speaker') {
    const videosList = document.getElementsByTagName('video');
    for (let item of videosList) {
      if (item.id !== selector) {
        await changeSpeaker(deviceId, item.id);
      }
    }

  }
};

const changeMicro = async (deviceId, selector) => {
  const stream = WTSession.getLocalStream();
  const session = WTSession.getSession();

  if (stream) {
    const videoTrack = stream.getVideoTracks()[0];
    const constraints = {
      audio: { deviceId }
    };

    const newStream = await navigator.mediaDevices.getUserMedia(constraints);
    newStream.addTrack(videoTrack);

    document.getElementById(selector).srcObject = newStream;

        WTSession.setLocalStream(newStream);
        session.stream = newStream;

    if (session.serviceMode === SERVICE_MODES.MESH) {
      const participants = session.participants;

      participants.forEach((participant) => {
        replaceTrackForParticipant(participant, newStream, 'audio');
      });
    } else if (session.serviceMode === SERVICE_MODES.SFU) {
      replaceTrackForParticipant(session.localParticipant, newStream, 'audio');
    }
  }
};

const changeVideo = async (deviceId, selector) => {
  const stream = WTSession.getLocalStream();
  const session = WTSession.getSession();

  if (stream) {
    const audioTrack = stream.getAudioTracks()[0];
    const videoTrack = stream.getVideoTracks()[0];

    const constraints = {
      video: { deviceId }
    };

    if (videoTrack) {
      videoTrack.stop();
    }

    const newStream = await navigator.mediaDevices.getUserMedia(constraints);

    newStream.addTrack(audioTrack);
    document.getElementById(selector).srcObject = newStream;
    WTSession.setLocalStream(newStream);

    if (session.serviceMode === SERVICE_MODES.MESH) {
      const participants = session.participants;

      participants.forEach((participant) => {
        replaceTrackForParticipant(participant, newStream, 'video');
      });
    } else if (session.serviceMode === SERVICE_MODES.SFU) {
      replaceTrackForParticipant(session.localParticipant, newStream, 'video');
    }
  }
};

const changeSpeaker = async (deviceId, selector) => {
  const element = document.getElementById(selector);
  element.setSinkId(deviceId);
};

export const replaceTrackForParticipant = (participant, newStream, type) => {
  if (participant.local) {
    participant.detectSpeaker(newStream);
  }

  const senders = participant.peerConnection.getSenders();
  const trackForReplacement = type === 'audio' ? newStream.getAudioTracks()[0] : newStream.getVideoTracks()[0];
  if (senders) {
    const sender = senders.find(s => s.track && s.track.kind === type);
    if (sender) {
      sender.replaceTrack(trackForReplacement).then(function() {
        console.debug('New track is being published');
      }).catch(function(error) {
        console.error('Error replacing track', error);
      });
    } else {
      console.error('There is no suitable track for replacement');
    }
  }
};

export default changeMediaDevice;
