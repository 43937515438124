class WTSession {

    static setSession(s) {
        this._session = s;
    }

    static getSession() {
        return this._session;
    }

    static getIceServers() {
        return this._iceServers;
    }

    static setIceServers(s) {
        this._iceServers = s;
    }

    static setLocalStream(s) {
        this._stream = s;
    }

    static getLocalStream() {
        return this._stream;
    }
}

export default WTSession;



