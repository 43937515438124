/**
 * @module onStreamCreated
 */
let callback = null;

/**
 *
 * @param cb
 */
const onStreamCreated = (cb) => {
  callback = cb;
};
/**
 *
 * @param stream
 */
export const onStreamCreatedListener = (stream) => {
  if(callback) {
    callback(stream);
  }
};

export default onStreamCreated;

