import WTSession from '../../entities/WTSession/WTSession';
import {SERVICE_MODES} from '../../constants/modes';

/**
 * @module screenSharing
 */

let sharingMediaSource;

/**
 * @member startScreenCapture
 * @returns {Promise<MediaStream>}
 */
const startScreenCapture = async () => {
    if (navigator.getDisplayMedia) {
        return await navigator.getDisplayMedia({video: true});
    } else if (navigator.mediaDevices.getDisplayMedia) {
        return await navigator.mediaDevices.getDisplayMedia({video: true});
    } else {
        return await navigator.mediaDevices.getUserMedia({video: {mediaSource: 'screen'}});
    }
};

/**
 * @member stopCapturing
 * @param node - Html video element participantId
 * @returns {Promise<void>}
 */
const stopCapturing = async (node) => {
    if (sharingMediaSource) {
        sharingMediaSource.getTracks().forEach((track) => track.kind === 'video' && track.stop());

        const webcamStream = WTSession.getLocalStream();
        const Session = WTSession.getSession();
        Session.stream = webcamStream;

        if (Session.serviceMode === SERVICE_MODES.MESH) {
            const participants = Session.participants;

            participants.forEach((participant) => {
                replaceTrackForParticipant(participant, webcamStream);
            });
        } else if (Session.serviceMode === SERVICE_MODES.SFU) {
            replaceTrackForParticipant(Session.localParticipant, webcamStream);
        }

        setVideoStreamToUiElement(node, webcamStream);
    }
};

/**
 *
 * @param node - Html vide element participantId
 * @returns {Promise<void>}
 */
const startScreenSharing = async (node) => {
    const Session = WTSession.getSession();
    const screenSharingStream = await startScreenCapture();

    sharingMediaSource = screenSharingStream;

    setVideoStreamToUiElement(node, screenSharingStream, {
        transform: 'rotateY(0deg)',
        webkitTransform: 'rotateY(0deg)'
    });

    screenSharingStream.getVideoTracks()[0].onended = function () {
        stopCapturing(node);
    };

    screenSharingStream.addTrack(WTSession.getLocalStream().getAudioTracks()[0]);

    Session.stream = screenSharingStream;

    if (Session.serviceMode === SERVICE_MODES.MESH) {
        const participants = Session.participants;

        participants.forEach((participant) => {
            replaceTrackForParticipant(participant, screenSharingStream);
        });
    } else if (Session.serviceMode === SERVICE_MODES.SFU) {
        replaceTrackForParticipant(Session.localParticipant, screenSharingStream);
    }
};

/**
 * @member replaceTrackForParticipant
 * @param participant
 * @param screenSharingStream
 */
export const replaceTrackForParticipant = (participant, screenSharingStream) => {
    const senders = participant.peerConnection.getSenders();
    if (senders) {
        const videoSender = senders.find(s => s.track.kind === 'video');

        if (videoSender) {
            videoSender.replaceTrack(screenSharingStream.getVideoTracks()[0]);
        }
    }
};

/**
 *
 * @param node - Html video element participantId
 * @returns {Promise<void>}
 */
const stopScreenSharing = async (node) => {
    try {
        await stopCapturing(node);
    } catch (error) {
        console.log(error);
    }
};

/**
 * @member setVideoStreamToUiElement
 * @param {string} node - Html video element participantId
 * @param {object} streamSource - media source
 * @param {object} style - style object like {translate: '', }
 */
export const setVideoStreamToUiElement = (node, streamSource, style) => {
    const videoElement = document.getElementById(node);

    if (!videoElement) {
        return;
    }
    videoElement.srcObject = streamSource;
    if (style) {
        videoElement.style.transform = 'rotateY(0deg)';
        videoElement.style.webkitTransform = 'rotateY(0deg)';
    }
};


export {
    startScreenSharing,
    stopScreenSharing
};
