/**
 * @module disconnect
 */
import WTSession from '../../entities/WTSession/WTSession';
import {onDisconnectedListener} from '../SessionListeners/onDisconnected';

/**
 *
 */
export const disconnect = () => {
  const session = WTSession.getSession();
  if(session) {
    session.participants.forEach((participant) => {
      participant.disconnect();
    });

    const stream = WTSession.getLocalStream();

    if(stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
        stream.removeTrack(track);
      });
    }

    if(session.localParticipant) {
      session.localParticipant.leaveRoom();
    }
    session.close();
    onDisconnectedListener();
  }
};
