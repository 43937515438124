/**
 * @module onConnected
 */
let callback = null;
/**
 * @param cb - Callback for session connected
 */
const onConnected = (cb) => {
    callback = cb;
};

/**
 *
 * @param participants
 */
export const onConnectedListener = (participants) => {
    if(callback) {
        callback(participants);
    }
};

export default onConnected;
