import * as actionTypes from './actionTypes'

export const openPanel = (status) => (dispatch) => {
  const action = {
    type: actionTypes.OPEN_CONTROL_PANEL,
    payload: status
  }
  dispatch(action)
}

export const setParticipants = (participantList) => (dispatch) => {
  const action = {
    type: actionTypes.SET_PARTICIPANTS,
    payload: participantList
  }
  dispatch(action)
}
export const addParticipant = (participant) => (dispatch) => {
  const action = {
    type: actionTypes.ADD_PARTICIPANT,
    payload: participant
  }
  dispatch(action)
}
export const setActiveTab = (tabIndex) => (dispatch) => {
  const action = {
    type: actionTypes.SET_ACTIVE_TAB,
    payload: tabIndex
  }
  dispatch(action)
}
export const setActiveEmoji = (indexEmoji) => (dispatch) => {
  const action = {
    type: actionTypes.SET_ACTIVE_EMOJI,
    payload: indexEmoji
  }
  dispatch(action)
}
