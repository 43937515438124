import WTSession from '../../entities/WTSession/WTSession';
import { MediaStates, MediaTypes, VideoCodecs } from '../../constants/media';
import {onParticipantMediaStreamChangedListener} from '../ParticipantListeners/onParticipantMediaStreamChanged';
import { setVideoStreamToUiElement} from './screenSharing';
import {SERVICE_MODES} from '../../constants/modes';
import {replaceTrackForParticipant} from '../MediaDevices/ChangeMediaDevices';
import { getFakeVideoTrack } from './dummyTracks';

/**
 * @module localMedia
 */
/**
 * @member toggleAudio
 * @param state
 */
const toggleAudio = (state) => {
    const stream = WTSession.getLocalStream();
    const session = WTSession.getSession();
    if(stream) {
        const tracks = stream.getTracks();
        const audioTrack = tracks.find(track => track.kind === 'audio');

        if(audioTrack) {
            audioTrack.enabled = state;
            if(!state){
                audioTrack.stop();
            }else{
                const localPAudioDevice =
                    session.localParticipant.devices !== null && session.localParticipant.devices['microphone']
                        ? {deviceId: session.localParticipant.devices['microphone'].deviceId}: true;
                const constraints = {
                    audio: localPAudioDevice
                };
                navigator.mediaDevices.getUserMedia(constraints).then(result => {
                    const tracks = result.getAudioTracks();
                    const trackToBeAdded = tracks[0];
                    const currentStreamTrack = stream.getAudioTracks();
                    const stoppedTrack = currentStreamTrack.filter(item => item.readyState === 'ended' || trackToBeAdded.label === item.label );
                    if(stoppedTrack.length)
                        stoppedTrack.map((item) => {
                            stream.removeTrack(item);
                        });
                    stream.addTrack(tracks[0]);
                    WTSession.setLocalStream(stream);

                    session.stream = stream;


                    if (session.serviceMode === SERVICE_MODES.MESH) {
                        const participants = session.participants;

                        participants.forEach((participant) => {
                            replaceTrackForParticipant(participant, stream, 'audio');
                        });
                    } else if (session.serviceMode === SERVICE_MODES.SFU) {
                        replaceTrackForParticipant(session.localParticipant, stream, 'audio');
                    }

                    setVideoStreamToUiElement('video-' + session.localParticipant.participantId, stream, {});
                });
            }


            const localParticipant = session.localParticipant;

            if(localParticipant && session.isLocalParticipantPublished) {
                const nextVal = state ? MediaStates.ENABLED : MediaStates.DISABLED;
                localParticipant.updateSettings(MediaTypes.AUDIO, nextVal);
                onParticipantMediaStreamChangedListener(localParticipant.participantId, MediaTypes.AUDIO, nextVal);
            }
        }
    }
    session.localParticipant.settings.mutedAudio = !state;
};

/**
 * Get config of quality from participant object and set it to new video track
 * @param qualityConfig - state of quality configuration from Participant.videoQuality
 * @param videoTrack - recreated video track after switch camera on
 */
const restoreVideoQuality = (qualityConfig, videoTrack) => {
    if(qualityConfig) {
        videoTrack.applyConstraints({...qualityConfig});
    }
};


/**
 * @member toggleVideo
 * @param state
 */
const toggleVideo = (state) => {
    const stream = WTSession.getLocalStream();
    const session = WTSession.getSession();
    if (stream) {
        const tracks = stream.getTracks();
        const videoTrack = tracks.find(track => track.kind === 'video');

        if(videoTrack) {
            let isSkipCrashNeeded = false; // prevents safari 15 from crash on video disabling
            if (global.BrowserDetails.browser === 'safari') {
                const userAgent = navigator.userAgent;
                const excludedVersion = '15.';
                const {videoCodecs} = WTSession.getSession().constraints;
                const isH264Forced = videoCodecs.forcingCodec === VideoCodecs.H264;
                const isH264Prioritized = !videoCodecs.forcingCodec && videoCodecs.priorityList && videoCodecs.priorityList[0] === VideoCodecs.H264;
                const isDefaultConstraints = Object.keys(videoCodecs).length === 0;
                isSkipCrashNeeded = userAgent.indexOf(excludedVersion) > 0 && (isH264Forced || isH264Prioritized || isDefaultConstraints);
            }
            if(!isSkipCrashNeeded) {
                videoTrack.enabled = state;
            }
            if (!state) {
                const fakeVideoTrack = getFakeVideoTrack();
                videoTrack.stop();
                stream.removeTrack(videoTrack);
                stream.addTrack(fakeVideoTrack);

                session.localParticipant.peerConnection.getSenders()
                  .find(s => s.track && s.track.kind === 'video')
                  .replaceTrack(fakeVideoTrack)
                  .then(() => {
                      console.debug('Fake track is being published');
                  }).catch((error) => {
                        console.error('Error replacing fake track', error);
                  });
                WTSession.setLocalStream(stream);

                session.stream = stream;

            }
        }

        if(state) {
            const localPVideoDevice =
                session.localParticipant.devices !== null && session.localParticipant.devices['camera']
                    ? { deviceId: session.localParticipant.devices['camera'].deviceId }: true;
            const constraints = {
                video: localPVideoDevice
            };
            navigator.mediaDevices.getUserMedia(constraints).then(result => {
                const trackToBeAdded = result.getVideoTracks()[0];
                const currentStreamTrack = stream.getVideoTracks();
                currentStreamTrack.map((item) => {
                    stream.removeTrack(item);
                });
                restoreVideoQuality(session.localParticipant.videoQuality, trackToBeAdded);
                stream.addTrack(trackToBeAdded);
                WTSession.setLocalStream(stream);

                session.stream = stream;

                if (session.serviceMode === SERVICE_MODES.MESH) {
                    const participants = session.participants;

                    participants.forEach((participant) => {
                        replaceTrackForParticipant(participant, stream, 'video');
                    });
                } else if (session.serviceMode === SERVICE_MODES.SFU) {
                    replaceTrackForParticipant(session.localParticipant, stream, 'video');
                }
                const localParticipant = session.localParticipant;

                if (localParticipant && session.isLocalParticipantPublished) {
                    const nextVal = state ? MediaStates.ENABLED : MediaStates.DISABLED;
                    localParticipant.updateSettings(MediaTypes.VIDEO, nextVal);
                    onParticipantMediaStreamChangedListener(localParticipant.participantId, MediaTypes.VIDEO, nextVal);
                }

                setVideoStreamToUiElement('video-' + session.localParticipant.participantId, stream, {});
            });
        }

        const localParticipant = session.localParticipant;

        if (localParticipant && session.isLocalParticipantPublished && !state) {
            const nextVal = state ? MediaStates.ENABLED : MediaStates.DISABLED;
            localParticipant.updateSettings(MediaTypes.VIDEO, nextVal);
            onParticipantMediaStreamChangedListener(localParticipant.participantId, MediaTypes.VIDEO, nextVal);
        }
    }
    session.localParticipant.settings.mutedVideo = !state;
};

const fakeVTracks = () => {
    const stream = WTSession.getLocalStream();
    const session = WTSession.getSession();
    if (stream) {
        const tracks = stream.getTracks();
        const videoTrack = tracks.find(track => track.kind === 'video');

        const fakeVideoTrack = getFakeVideoTrack();
        videoTrack.stop();
        stream.removeTrack(videoTrack);
        stream.addTrack(fakeVideoTrack);

        WTSession.setLocalStream(stream);

        session.stream = stream;
    }
};
const fakeATracks = () => {
    const stream = WTSession.getLocalStream();
    const session = WTSession.getSession();
    if (stream) {
        const tracks = stream.getTracks();
        const audioTrack = tracks.find(track => track.kind === 'audio');

        if (audioTrack) {
            audioTrack.stop();
        }
        WTSession.setLocalStream(stream);

        session.stream = stream;
    }
}

/**
 * @member checkEnabled
 * @param kind
 * @returns {boolean}
 */
const checkEnabled = (kind) =>  {
    const stream = WTSession.getLocalStream();
    let result = false;
    if(stream) {
        const tracks = stream.getTracks();
        const track = tracks.find(track => track.kind === kind);

        result = track && track.enabled && track.readyState === 'live';
    }

    return result;
};
export default {
    /**
     * @method disableAudio() - Make local audio disabled.
     */
    disableAudio: () => toggleAudio(false),
    /**
     * @method enableAudio() - Make local audio enabled.
     */
    enableAudio: () => toggleAudio(true),
    /**
     * @method disableVideo() - Make local video disabled.
     */
    disableVideo: () => toggleVideo(false),
    /**
     * @method enableVideo() - Make local video enabled.
     */
    enableVideo: () => toggleVideo(true),
    /**
     * @method isAudioEnabled() - Return status of local audio.
     * @return {boolean} - True: if local audio enabled
     */
    isAudioEnabled: () => checkEnabled('audio'),
    /**
     * @method isVideoEnabled() - Return status of local video.
     * @return {boolean} - True: fi local video enabled
     */
    isVideoEnabled: () => checkEnabled('video'),
    setFakeVideo: () => fakeVTracks(),
    setFakeAudio: () => fakeATracks()
};



