export const MediaTypes = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO'
};

export const MediaStates = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED'
};

export const MediaStatesMapper = {
  DISABLED: false,
  ENABLED: true
};

export const VideoCodecs = {
  H264: 'H264',
  VP8: 'VP8',
  VP9: 'VP9',
};
