const FULL_PARTICIPANT ='FULL_PARTICIPANT';
const AV_BROADCASTER ='AV_BROADCASTER';
const A_BROADCASTER ='A_BROADCASTER';
const V_BROADCASTER ='V_BROADCASTER';
const VIEWER ='VIEWER';
const MESSAGE_ONLY ='MESSAGE_ONLY';

export default {
  FULL_PARTICIPANT,
  AV_BROADCASTER,
  A_BROADCASTER,
  V_BROADCASTER,
  VIEWER,
  MESSAGE_ONLY,
};
