let fakeVideoTrack = null;
let fakeAudioTrack = null;

const getFakeAudioTrack = () => {
  if(!fakeAudioTrack) {
    let audioContext = new AudioContext(), oscillator = audioContext.createOscillator();
    let mediaStreamDestination = oscillator.connect(audioContext.createMediaStreamDestination());
    oscillator.start();
    fakeAudioTrack = mediaStreamDestination.stream.getAudioTracks()[0];
  }

  return fakeAudioTrack;
};

// used to let video tag play audio with muted MediaStreamTrack
// if you will pass it over network to let audio works you need to fake an
// activity on canvas like:
// requestAnimationFrame(function measure() {
//   canvas.getContext('2d').fillRect(0, 0, width, height);
//   requestAnimationFrame(measure);
// });
const getFakeVideoTrack = ({ width = 640, height = 480 } = {}) => {
  if (!fakeVideoTrack) {
    let canvas = Object.assign(document.createElement('canvas'), { width, height });
    canvas.getContext('2d').fillRect(0, 0, width, height);
    let stream = canvas.captureStream();
    fakeVideoTrack = stream.getVideoTracks()[0];
  }

  return fakeVideoTrack;
};

const getFakeStream = (...args) => new MediaStream([getFakeAudioTrack(...args), getFakeVideoTrack()]);

export {
  getFakeStream,
  getFakeVideoTrack,
  getFakeAudioTrack
};
