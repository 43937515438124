import WTSession from '../../entities/WTSession/WTSession';

const updateParticipantName = (participantName) => {
    const session = WTSession.getSession();
    const localParticipant = session.localParticipant;
    localParticipant.updateParticipantName(participantName);
};

export {
    updateParticipantName
};
