import WebRTCMetrics from 'webrtcmetrics';
import mosReport from '../statsCallback/mosReport';
import {qualityConstants} from '../../constants/qualityConstants';

const collectingStatsPeriod = -1;
const delayBeforeStartCollect = 0;

export class MetricCollector {
    metrics = null;
    probe = null;

    constructor(){
        this.metrics = new WebRTCMetrics({
            refreshEvery: 1000,   // Optional. Refresh every 1 seconds
            startAfter: delayBeforeStartCollect,     // Optional. Start collecting stats after 5 seconds
            stopAfter: collectingStatsPeriod,
        });
        this.metrics.setupLogLevel('WARN');
    }

    getReport(report) {
        const statsObj = {
            video:'',
            audio:'',
            mos: 0,
            rtt: 0,
        };
        if (report.audio.mos_emodel_in <= 3.6) {
            statsObj.video = statsObj.audio = qualityConstants.bad;
        } else if (report.audio.mos_emodel_in > 3.6 && report.audio.mos_emodel_in <= 4) {
            statsObj.video = statsObj.audio = qualityConstants.good;
        } else if (report.audio.mos_emodel_in > 4) {
            statsObj.video = statsObj.audio =  qualityConstants.excellent;
        }
        statsObj.mos = report.audio.mos_emodel_in;
        statsObj.rtt = report.video.delta_rtt_ms_out;
        mosReport({ participantId: report.pname, stats: { ...statsObj,  detail: report}  });
    }

    stopCollecting(){
        this.metrics.stopAllProbes();
    }

    makeProbePeerConnection({participantId, peerConnection }){
        if(peerConnection) {
            this.probe = this.metrics.createProbe(peerConnection, {
                pname: participantId,
                ticket: true,
                record: true,
            });
            this.probe.onreport = this.getReport;
            peerConnection.onconnectionstatechange = state => {
                if(state.currentTarget.connectionState === 'connected' && state.currentTarget.iceConnectionState === 'connected'){
                    if(!this.probe.isRunning && this.metrics.probes.length > 1){
                        this.metrics.startAllProbes();
                    }
                    if(state.currentTarget.connectionState === 'closed'){
                        if(this.probe.isRunning){
                            this.probe.stop();
                        }
                    }
                }
                if(state.currentTarget.connectionState === 'closed'){
                    if(this.probe.isRunning){
                        this.probe.stop();
                    }
                }
            };
        }
    }

}
