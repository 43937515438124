import hark from 'hark';
import {onStreamCreatedListener} from '../../api/SessionListeners/onStreamCreated';
import WTSession from '../WTSession/WTSession';
import {SERVICE_MODES} from '../../constants/modes';
import {VideoCodecs} from '../../constants/media';
import participantType from '../../constants/participantType';
import {createPeerConnection} from '../../helpers/createPeerConnection';
import {onParticipantSpeakingListener, onParticipantStopSpeakingListener} from '../../api/ParticipantListeners/onParticipantSpeaking';
import {parse as SDPparser, write as SDPwriter } from 'sdp-transform';
import { getFakeVideoTrack } from '../../api/Participant/dummyTracks';

/**
 * signalingServer - jsonRPC instance
 * participantId-  string
 * participantName - string
 * type - enum types [broadkaster, fullparticipant etc]
 * stream - webcam Media stream
 * streamConstraints - object {audio: boolean, video: boolean, videoCodecs: Object}
 * settings - object {mutedAudio: boolean, mutedVideo: boolean}
 */

class Participant {
    _signalingServer = null;

    constructor(signalingServer, participantId, participantName, type, stream, streamConstraints, settings, serviceMode,
        local, isCelebrity) {
        this._signalingServer = signalingServer;
        this.participantId = participantId;
        this.participantName = participantName;
        this.type = type;
        this.stream = stream;
        this.streamConstraints = streamConstraints;
        this.settings = settings || {mutedAudio: false, mutedVideo: false};
        this.videoQuality = null;
        this.devices = null;
        this.speechEvents = null;
        this.isBroadcaster = false;
        this.isViewer = false;
        this.isCelebrity = isCelebrity;
        this.isSynchronizer = false;
        this.local = local || false;
        this.peerIsReady = false;
        this.iceCandidates = [];
        this.serviceMode = serviceMode;
        this.peerConnection = type !== participantType.MESSAGE_ONLY ? createPeerConnection(participantId) : null;

    }

    prioritizeCodecs(sdp, sdpType = 'offer') {
        const parsedSDP = SDPparser(sdp);
        const videoCodecsConstraints = WTSession.getSession().constraints.videoCodecs || {};
        const priorityList = videoCodecsConstraints.priorityList || [VideoCodecs.H264, VideoCodecs.VP8];
        let forcingCodec = videoCodecsConstraints.forcingCodec || false;
        const applyToAnswer = videoCodecsConstraints.applyToAnswer || false;
        // due to Safari 15.x problems we need to force usage of VP8 on SFU mode
        const userAgent = navigator.userAgent;
        const excludedVersion = 'iPhone OS 15_';
        const isForcingVP8Needed = global.BrowserDetails.browser === 'safari' && userAgent.indexOf(excludedVersion) > 0;
        if(this.serviceMode === SERVICE_MODES.SFU && isForcingVP8Needed) {
            forcingCodec = VideoCodecs.VP8;
        }
        if(parsedSDP.media) {
            parsedSDP.media = parsedSDP.media.map(mediaData => {
                if(mediaData.type === 'video') {
                    if(Array.isArray(priorityList)) {
                        mediaData.payloads = this.rearrangePayload(mediaData.rtp, priorityList);
                    }
                    if(forcingCodec) {
                        if(sdpType !== 'answer' || sdpType === 'answer' && applyToAnswer)  {
                            mediaData.rtp = mediaData.rtp.filter(rtpData => rtpData.codec && rtpData.codec === forcingCodec);
                            const payloadIds = mediaData.rtp.map(rtpData => rtpData.payload);
                            mediaData.payloads = payloadIds.join(' ').trim();
                            mediaData.fmtp = mediaData.fmtp.filter(fmtpData => payloadIds.includes(fmtpData.payload));
                            mediaData.rtcpFb = mediaData.rtcpFb.filter(rtcpFbData => payloadIds.includes(rtcpFbData.payload));
                        }
                    }
                }
                return mediaData;
            });
        }
        return SDPwriter(parsedSDP);
    }

    rearrangePayload(rtp, priorityList) {
        const priorityMap = priorityList.reduce((acc, elem) => {
            acc[elem] = [];
            return acc;
        }, {});
        priorityMap.other = [];
        rtp.forEach(rtpData => {
            if(rtpData.codec) {
                if(priorityList.includes(rtpData.codec)) {
                    priorityMap[rtpData.codec].push(rtpData.payload);
                } else {
                    priorityMap.other.push(rtpData.payload);
                }
            }
        });

        return Object.values(priorityMap).reduce((acc, elem) => (`${acc} ${elem.join(' ')}`), '').trim();
    }

    updateStreamConstraints(constraints) {
        this.streamConstraints = constraints;
    }

    updateQualityConfig(qualityConfig){
        this.videoQuality = qualityConfig;
    }

    updateStreamSettings(settings) {
        this.settings = settings;
    }

    updateUserType(isViewer, isBroadcaster, isSynchronizer) {
        this.isViewer = isViewer;
        this.isBroadcaster = isBroadcaster;
        this.isSynchronizer = isSynchronizer;
    }

    updateParticipantName(participantName) {

        this._signalingServer.sendRequest('updateParticipantInfo', {userName: participantName}, (err, res) => {
            if(res) {
                this.participantName = participantName;
            }
        });
    }

    setParticipantName(name) {
        this.participantName = name;
    }

    publish() {

        if(this.stream) {
            this.stream.getTracks().forEach(track =>
              this.peerConnection && this.peerConnection.addTrack(track, this.stream)
            );

            this.detectSpeaker(this.stream);
        }

        if (this.serviceMode === SERVICE_MODES.SFU) {
            this.peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            }).then((desc) => {
                // if(desc.sdp) {
                //     desc.sdp = this.prioritizeCodecs(desc.sdp);
                // }
                this.peerConnection.setLocalDescription(desc).then(() => {
                    this._signalingServer.sendRequest('startMediaStream', {
                        sdpOffer: desc.sdp,
                        doLoopback: false,
                        audioEnabled: this.streamConstraints.audio,
                        videoEnabled: this.streamConstraints.video,
                        mutedAudio: this.settings.mutedAudio,
                        mutedVideo: this.settings.mutedVideo,
                    }, (err, res) => {
                        if(!err) {
                            this.peerConnection.setRemoteDescription({type: 'answer', sdp: res.sdpAnswer}).then(() => {
                                this.peerIsReady = true;
                                this.processIceCandidates();

                                onStreamCreatedListener({
                                    participantId: this.participantId,
                                    participantName: this.participantName,
                                    type: this.type,
                                    stream: this.stream,
                                    settings: this.settings,
                                    isBroadcaster: this.isBroadcaster,
                                    isViewer: this.isViewer,
                                    isCelebrity: this.isCelebrity,
                                    local: true,
                                    streamConstraints: this.streamConstraints
                                });
                            });
                        }
                    });
                });
            });
        } else {
            this._signalingServer.sendRequest('startMediaStream', {
                doLoopback: false,
                audioEnabled: this.streamConstraints.audio,
                videoEnabled: this.streamConstraints.video,
                mutedAudio: this.settings.mutedAudio,
                mutedVideo: this.settings.mutedVideo,
            }, (err, res) => {
                if(!err) {
                    onStreamCreatedListener({
                        participantId: this.participantId,
                        participantName: this.participantName,
                        type: this.type,
                        stream: this.stream,
                        settings: this.settings,
                        isBroadcaster: this.isBroadcaster,
                        isViewer: this.isViewer,
                        isCelebrity: this.isCelebrity,
                        local: true,
                        streamConstraints: this.streamConstraints
                    });
                }
            });
        }
    }

    addIceCandidate(candidate) {
        if (!candidate.candidate) {
            //empty ice candidate is not working in Safari
            return;
        }
        this.iceCandidates.push(candidate);

        if (this.peerIsReady) {
            this.processIceCandidates();
        }
    }

    processIceCandidates() {
        const candidates = this.iceCandidates;
        this.iceCandidates = [];

        for (let i = 0; i < candidates.length; i++) {
            this.peerConnection.addIceCandidate(new RTCIceCandidate(candidates[i])).catch(e => console.error('Add Ice candidate error: ', e));
        }
    }

    publishRemote() {
        if (this.serviceMode === SERVICE_MODES.MESH) {
            const stream = WTSession.getSession().stream;

            if(stream) {
                stream.getTracks().forEach((track) => {
                    this.peerConnection && this.peerConnection.addTrack(track, stream);
                });
            }
        }

        if(this.peerConnection) {
            this.stream = new MediaStream();
            this.peerConnection.ontrack = (e) => {
                if(this.streamConstraints.video && e.track.kind === 'video'){
                    const trackToBeAdded = this.settings.mutedVideo ? getFakeVideoTrack() : e.track;
                    this.stream.addTrack(trackToBeAdded, this.stream);
                    if(this.settings.mutedVideo) {
                        // save original track to restore on video enabling
                        this.originalTrack = e.track;
                    }
                }
                if(this.streamConstraints.audio && e.track.kind === 'audio'){
                    this.stream.addTrack(e.track, this.stream);
                }
            };

            this.peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            }).then(desc => {
                // if (desc.sdp) {
                //     desc.sdp = this.prioritizeCodecs(desc.sdp);
                // }
                this.peerConnection.setLocalDescription(desc).then(() => {
                    this._signalingServer.sendRequest('subscribeOnMediaStream', {
                        participantId: this.participantId,
                        sdpOffer: desc.sdp
                    }, (err, res) => {
                        if (!err) {

                            this.peerConnection.setRemoteDescription({type: 'answer', sdp: res.sdpAnswer}).then(() => {
                                this.peerIsReady = true;
                                this.processIceCandidates();

                                this.detectSpeaker(this.stream);

                                onStreamCreatedListener({
                                    participantId: this.participantId,
                                    participantName: this.participantName,
                                    type: this.type,
                                    stream: this.stream,
                                    streamConstraints: this.streamConstraints,
                                    settings: this.settings,
                                    isBroadcaster: this.isBroadcaster,
                                    isViewer: this.isViewer,
                                    isCelebrity: this.isCelebrity,
                                    local: false,
                                });

                            }).catch(err => console.error('error => 1', err));
                        }
                    });
                }).catch(err => console.error('error => 2 ', err));
            }).catch(err => console.error('error => 3 ', err));
        }
    }

    handleIncomingConnection(offer) {
        const stream = WTSession.getSession().stream;
        if(stream) {
            stream.getTracks().forEach((track) => {
                this.peerConnection && this.peerConnection.addTrack(track, stream);
            });
        }

        if(this.peerConnection) {
            this.stream = new MediaStream();

            this.peerConnection.ontrack = (e) => {
                if(this.settings.mutedVideo && e.track.kind === 'video') {
                    this.stream.addTrack(getFakeVideoTrack());
                    this.originalTrack = e.track;
                } else {
                    this.stream.addTrack(e.track, this.stream);
                }
            };

            this.peerConnection.setRemoteDescription({type: 'offer', sdp: offer}).then(() => {
                this.peerConnection.createAnswer().then((desc) => {
                    // if (desc.sdp) {
                    //     desc.sdp = this.prioritizeCodecs(desc.sdp, 'answer');
                    // }
                    this.peerConnection.setLocalDescription(desc).then(() => {
                        this.peerIsReady = true;
                        this.processIceCandidates();

                        this._signalingServer.sendRequest('incomingConnectionResponse', {
                            accept: true,
                            participantId: this.participantId,
                            sdpAnswer: desc.sdp
                        }, (error, response) => {
                            if (!error) {
                                if (this.stream && this.stream.getTracks().length) {
                                    this.detectSpeaker(this.stream);
                                }

                                onStreamCreatedListener({
                                    participantId: this.participantId,
                                    participantName: this.participantName,
                                    type: this.type,
                                    stream: this.stream,
                                    settings: this.settings,
                                    isBroadcaster: this.isBroadcaster,
                                    isViewer: this.isViewer,
                                    isCelebrity: this.isCelebrity,
                                    streamConstraints: this.streamConstraints
                                });
                            }
                        });
                    });
                });
            }).catch((e) => console.error('e => ', e));
        }
    }

    updateSettings(mediaType, mediaState) {
        this._signalingServer.sendRequest('updateSettings', {
            mediaType,
            mediaState
        }, (error) => {
            if (error) {
                console.error('updateSettings error: ', error);
            }
        });
    };

    detectSpeaker(stream) {
        if (this.speechEvents) {
            this.speechEvents.stop();
        }

        this.speechEvents = hark(stream);
        this.speechEvents.on('speaking', () => {
            onParticipantSpeakingListener(this.participantId);
        });

        this.speechEvents.on('stopped_speaking', () => {
            onParticipantStopSpeakingListener(this.participantId);
        });
    }

    disconnect() {
        this.peerConnection.close();
        if(this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
    };

    leaveRoom() {
        this._signalingServer.sendRequest('leaveRoom');
        this._signalingServer.close();
    }
}

export default Participant;
