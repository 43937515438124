/**
 * @module onLocalStreamChanged
 */
let callback = null;
/**
 *
 * @param cb
 */
const onLocalStreamCreated = (cb) => {
    callback = cb;
};

/**
 *
 * @param stream
 */
export const onLocalStreamCreatedListener = (stream) => {
    if(callback) {
        callback(stream);
    }
};

export default onLocalStreamCreated;
