let callback = null;
/**
 * @namespace ErrorListeners
 * @property {module:sessionErrors} SessionErrors Handling session errors
 */
/**
 * @module sessionErrors
 */

const onSessionError = (cb) => {
    if(cb instanceof Function) {
        callback = cb;
    }
};

export const onSessionErrorListener = (error) => {
    if(callback) {
        callback(error);
    }
};

/**
 * @method onSessionError
 * @param {function} cb - Callback calling if error is throw !
 */
export default onSessionError;
