import localMedia from './localMedia';
import remoteMedia from './remoteMedia';

import setMediaQuality from './setMediaQuality';
import { startScreenSharing, stopScreenSharing } from './screenSharing';
import {publishRemoteParticipant, disconnectRemoteParticipant} from './remoteParticipantControl';
import {updateParticipantName} from './updateParticipant';

/**
 * @namespace Participant
 * @property {module:localMedia} LocalMedia Module for controlling local audio and video
 * @property {module:remoteMedia} RemoteMedia  Remote control
 * @property {module:remoteParticipantControl} RemoteParticipantControl  Participant remote control
 * @property {module:screenSharing} ScreenSharing Contain methods for controlling screen sharing
 * @property {module:setMediaQuality} SetMediaQuality
 */
export default {
	enableAudio: localMedia.enableAudio,
	disableAudio: localMedia.disableAudio,
	isAudioEnabled: localMedia.isAudioEnabled,
	setFakeVideo: localMedia.setFakeVideo,
	setFakeAudio: localMedia.setFakeAudio,

	enableVideo: localMedia.enableVideo,
	disableVideo: localMedia.disableVideo,
	isVideoEnabled: localMedia.isVideoEnabled,

	toggleRemoteVideo: remoteMedia.toggleRemoteVideo,
	toggleRemoteAudio: remoteMedia.toggleRemoteAudio,
	isRemoteAudioEnabled: remoteMedia.isRemoteAudioEnabled,
	isRemoteVideoEnabled: remoteMedia.isRemoteVideoEnabled,

	startScreenSharing,
	stopScreenSharing,
	setMediaQuality,
	publishRemoteParticipant,
	disconnectRemoteParticipant,
	updateParticipantName
};
