import WTSession from '../entities/WTSession/WTSession';
import JsonRpcClient from '../services/JsonRpcClient';


export const createPeerConnection = (id) => {
    const signalingServer = new JsonRpcClient();


    const peerConnection = new RTCPeerConnection({iceServers: WTSession.getIceServers()});
    peerConnection.onicecandidate = (event) => {
        let candidate = event.candidate ? event.candidate.toJSON() : null;

        if(!candidate) {
            peerConnection.onicecandidate = null;

            candidate = {
                sdpMid: '0',
                candidate: '',
                sdpMLineIndex: 0
            };
        }
        peerConnection.onicecandidateerror = (e) => {
            console.log('ON CANDIDATE ERROR in Peer connection:', e);
        };
        peerConnection.oniceconnectionstatechange = () => {
            console.log('ON ICE CONNECTION STATE CHANGE', peerConnection.iceConnectionState);
            if(peerConnection.iceConnectionState === 'failed') {
                signalingServer.jsonRpcClient.sendRowMessage('leaveRoom', { forceConnectionLost: true });
            }
        };

        signalingServer.sendRequest('addIceCandidate', {
            participantId: id,
            ...candidate
        }, null);
    };

    return peerConnection;
};
