import onParticipantJoin from './onParticipantJoin';
import {onParticipantLeft} from './onParticipantLeft';

import {onParticipantStartSpeaking, onParticipantStopSpeaking} from './onParticipantSpeaking';

import onOrganizerReceived from './onOrganizerReceived';
import onParticipantMediaStreamChanged from './onParticipantMediaStreamChanged';

import {onParticipantRejected} from './onParticipantRejected';

import {onParticipantInfoUpdated} from './onParticipantInfoUpdated';

/**
 * @namespace ParticipantListeners
 * @property {module:detectSpeach} DetectSpeach Detect Speach
 * @property {module:onOrganizeReceived} onOrganizeReceived onOrganizeReceived
 * @property {module:onParticipantJoined} onParticipantJoin onParticipantJoin
 * @property {module:onParticipantLeft} onParticipantLeft onParticipantLeft
 * @property {module:onParticipantMediaStreamChanged} onParticipantMediaStreamChanged onParticipantMediaStreamChanged
 * @property {module:onParticipantRejected} onParticipantRejected onParticipantRejected
 */

export default {
  onParticipantJoin,
  onParticipantLeft,
  onParticipantSpeaking: onParticipantStartSpeaking,
  onParticipantStopSpeaking,
  onOrganizerReceived,
  onParticipantMediaStreamChanged,
  onParticipantRejected,
  onParticipantInfoUpdated
};
