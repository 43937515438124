import jsonRpcClient from '../../services/JsonRpcClient';
import {SERVICE_MODES} from '../../constants/modes';
import Participant from '../Participant/Participant';
import participantApi from '../../api/Participant';
import {onParticipantJoinedListener} from '../../api/ParticipantListeners/onParticipantJoin';
import {onNewMessageListener, onPlayerDataListener, onSyncDataListener} from '../../api/SessionListeners/onNewMessage';
import {onParticipantLeftListener} from '../../api/ParticipantListeners/onParticipantLeft';
import WTSession from '../WTSession/WTSession';
import {onOrganizerReceivedListener} from '../../api/ParticipantListeners/onOrganizerReceived';
import {MediaStatesMapper} from '../../constants/media';
import {onParticipantMediaStreamChangedListener} from '../../api/ParticipantListeners/onParticipantMediaStreamChanged';
import {onConnectedListener} from '../../api/SessionListeners/onConnected';
import {onParticipantInfoUpdatedListener} from '../../api/ParticipantListeners/onParticipantInfoUpdated';
import {onRemoteParticipantConnectionLostListener, onRemoteParticipantReconnectingListener, onRemoteParticipantReconnectedListener} from '../../api/ReconnectListener/remoteParticipant';
import {onSessionErrorListener} from '../../api/ErrorListeners/sessionErrors';
import {MetricCollector} from '../../services/rtcStatsCollector/webMetrics';

class Session {
    /**
     *
     * @param wsUrl - connection url
     * @param constraints  - {audio: boolean, video: boolean, videoCodecs: Object}
     * @param participantType - enum of types
     *
     * sessionId - uniq session identificator
     * ice servers - array of stun/turn servers
     * serviceMode - mesh/sfu
     *
     */
    constructor(wsUrl, constraints, participantType = 'FULL_PARTICIPANT') {
        this.signalingServer = null;
        this.wsUrl = wsUrl;
        this.sessionId = '';
        this.room = '';
        this.iceServers = {};
        this.serviceMode = '';
        this.constraints = constraints;
        this.participantType = participantType;
        this._stream = null;
        this.participants = [];
        this.localParticipant = null;
        this.isLocalParticipantPublished = false;
        this.secret = '';
        this._joinParams = {};
        this.statistic = new MetricCollector();
    }

    set stream(s) {
        this._stream = s;
    }

    get stream() {
        return this._stream;
    }

    set joinParams(params) {
        this._joinParams = params;
    }

    set statType(type){
        this._statType = type;
    }


    initConnection() {
        return new Promise((resolve, reject) => {
            const onConnected = (error) => {
                if (error) {
                    reject(error);
                }

                resolve();
            };

            if (this.signalingServer) {
                resolve();
            } else {
                this.signalingServer = new jsonRpcClient(this.wsUrl, onConnected);
            }
        });
    }

    getIceServers() {
        return new Promise((resolve, reject) => {
            this.signalingServer.sendRequest(
                'getIce',
                {},
                (error, response) => {

                    if (error) {
                        return reject(error);
                    }

                    const servers = response.value;

                    WTSession.setIceServers(servers);
                    this.iceServers = servers;

                    return resolve(servers);
                }
            );
        });
    }

    async connect(joinParams, withPublish, userAgent = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                await this.initConnection();
                await this.getIceServers();
                const isMesh = joinParams.networkTopology !== 'media-server' && this.serviceMode !== SERVICE_MODES.SFU;
                this.signalingServer.sendRequest('connect', {client: {mesh: false, user_agent: userAgent}, ...joinParams}, (error, response) => {
                    if (error) {
                        reject(error);
                    } else {
                        this.sessionId = response.sessionId;
                        this.serviceMode = response.serviceMode;
                        this.room = joinParams.room;
                        this.secret = response.secret;
                        const oldLocal = joinParams.oldLocalP ? joinParams.oldLocalP.settings : false;
                        const oldDevices = joinParams.devices ? joinParams.devices : false;

                        this.localParticipant = new Participant(
                            this.signalingServer,
                            this.sessionId,
                            joinParams.user,
                            this.participantType,
                            this._stream,
                            this.constraints,
                            oldLocal,
                            this.serviceMode,
                            true,
                            joinParams.isCelebrity || false);
                        this.statistic.makeProbePeerConnection({participantId:this.sessionId, peerConnection: this.localParticipant.peerConnection });


                        if(oldDevices){
                            this.localParticipant.devices = oldDevices;
                        }


                        this.participants.push(this.localParticipant);

                        const prevParticipants = response.value;

                        if(withPublish || this.isLocalParticipantPublished) {
                            this.localParticipant.publish();
                            this.isLocalParticipantPublished = true;
                        }

                        for (let i = 0; i < prevParticipants.length; i++) {
                            const {id: participantId, name, stream: streamConstraints, settings, isCelebrity} = prevParticipants[i];
                            const participant = new Participant(
                                this.signalingServer,
                                participantId,
                                name,
                                this.participantType,
                                null,
                                streamConstraints,
                                settings,
                                this.serviceMode,
                                false,
                                isCelebrity);
                            this.statistic.makeProbePeerConnection({participantId, peerConnection: participant.peerConnection });


                            this.participants.push(participant);

                            const isAudioOrVideoEnabled = streamConstraints.audio || streamConstraints.video;
                            if ((!WTSession.getLocalStream() && isAudioOrVideoEnabled) ||
                                (this.isLocalParticipantPublished && this.serviceMode === SERVICE_MODES.SFU &&
                                    isAudioOrVideoEnabled)) {
                                participant.publishRemote();
                            }
                        }
                        resolve(this.participants.filter(p => !p.local));
                    }
                });
                if(joinParams.fakeVideo === true)
                    participantApi.setFakeVideo();
                if(joinParams.fakeAudio === true){
                   participantApi.setFakeAudio();
                }
            } catch (e) {

                reject(e);
            }
        });
    }

    onParticipantJoined({participant, oldParticipantId}) {
        const {id: participantId, name, stream: streamConstraints, settings, isCelebrity} = participant;

        if(oldParticipantId) {
            onRemoteParticipantReconnectingListener(oldParticipantId);
        }

        const newParticipant = new Participant(
            this.signalingServer,
            participantId,
            name,
            this.participantType,
            null,
            streamConstraints,
            settings,
            this.serviceMode,
            false,
            isCelebrity);
        this.statistic.makeProbePeerConnection({participantId, peerConnection: newParticipant.peerConnection });

        this.participants.push(newParticipant);
        onParticipantJoinedListener(newParticipant);
    }

    onParticipantPublished({participant}) {
        const {id: participantId, stream: streamConstraints, oldParticipantId, settings } = participant;

        if(oldParticipantId) {
            onRemoteParticipantReconnectedListener(oldParticipantId);
        }

        const selectedParticipant = this.participants.find(p => p.participantId === participantId);
        selectedParticipant.updateStreamSettings(settings);
        selectedParticipant.updateStreamConstraints(streamConstraints);

        if(this.isLocalParticipantPublished || (!this.constraints.audio && !this.constraints.video)) {
            selectedParticipant.publishRemote();
        }

    }

    onParticipantLeft(participantId, connectionLost) {
        const selectedParticipant = this.participants.find(p => p.participantId === participantId);
        this.participants = this.participants.filter(p => p.participantId !== participantId);

        if(connectionLost) {
            onRemoteParticipantConnectionLostListener(participantId);
        }

        if(selectedParticipant) {
            selectedParticipant.disconnect();
            onParticipantLeftListener(participantId);
        }
    }

    onIncomingConnection({participant, sdpOffer}) {
        const {id: participantId, stream: streamConstraints, settings} = participant;
        const selectedParticipant = this.participants.find(p => p.participantId === participantId);
        selectedParticipant.updateStreamConstraints(streamConstraints);
        selectedParticipant.updateStreamSettings(settings);

        selectedParticipant.handleIncomingConnection(sdpOffer);
    }

    onIceCandidate(msg) {
        const candidate = {
            candidate: msg.candidate,
            sdpMid: msg.sdpMid,
            sdpMLineIndex: +msg.sdpMLineIndex
        };

        const participant = this.participants.find(p => p.participantId === msg.endpointName);

        if (!participant) {
            console.error(
                'Participant not found for endpoint ' +
                msg.endpointName +
                '. Ice candidate will be ignored.',
                candidate
            );
            return false;
        }

        participant.addIceCandidate(candidate);
    }

    async onSwitchMode({serviceMode}) {
        this.serviceMode = serviceMode;
        this._joinParams.oldLocalP = this.localParticipant;
        this._joinParams.videoQuality = this.localParticipant.videoQuality;
        this._joinParams.devices = this.localParticipant.devices;
        this.participants = [];

        try {
            const participants = await this.connect(this._joinParams);
            onConnectedListener(participants);
        } catch (e) {
            onSessionErrorListener(e);
        }
    }

    async processReconnect() {
        const joinParams = {
            user: this._joinParams.user || '',
            oldParticipantId: this.localParticipant.participantId || '',
            oldParticipantSecret: this.secret || '',
            isCelebrity: this.localParticipant.isCelebrity || '',
        };

        if(this.participants.length){
            this.participants.map(participant => {
                     participant.peerConnection.close();
            });
        }

        this.participants = [];

        try {
            const participants = await this.connect(joinParams);
            onConnectedListener(participants);
        } catch (e) {
            onSessionErrorListener(e);
        }
    }

    onSynchronizerChanged() {
        onOrganizerReceivedListener();
    }

    updateParticipantSettingsInPList({ participantId, mediaType, mediaState } ){
        const selectedParticipant = this.participants.find(p => p.participantId === participantId);
        let settings = {
            mutedAudio: selectedParticipant.settings.mutedAudio,
            mutedVideo: selectedParticipant.settings.mutedVideo
        };
        if(mediaState === 'DISABLED' && mediaType === 'AUDIO')
            settings.mutedAudio = true;
        else if (mediaState ==='ENABLED' && mediaType === 'AUDIO')
            settings.mutedAudio = false;
        if(mediaState === 'DISABLED' && mediaType === 'VIDEO')
            settings.mutedVideo = true;
        else if (mediaState ==='ENABLED' && mediaType === 'VIDEO')
            settings.mutedVideo = false;
        selectedParticipant.settings = settings;
    }

    onParticipantUpdateSettings = ({participantId, mediaType, mediaState}) => {
        const selectedParticipant = this.participants.find(p => p.participantId === participantId);
        this.updateParticipantSettingsInPList({participantId, mediaState, mediaType });
        if(selectedParticipant) {
            const stream = selectedParticipant.stream;

            if(stream) {
                const activeTrack = stream.getTracks().find(track => track.kind === mediaType.toLowerCase());

                if(activeTrack) {
                    activeTrack.enabled = MediaStatesMapper[mediaState];
                    onParticipantMediaStreamChangedListener(participantId, mediaType, mediaState);
                }
            }
        }
    };

    participantInfoUpdated = ({participant}) => {
        const selectedParticipant = this.participants.find(p => p.participantId === participant.id);

        if(selectedParticipant) {
            selectedParticipant.setParticipantName(participant.participantName);
            onParticipantInfoUpdatedListener(participant);
        }
    }

    getParticipants() {
        return this.participants;
    }

    onNewMessage({participantId, notification}) {
        const topic = notification.slice(0, notification.indexOf('_'));
        const message = notification.slice(notification.indexOf('_') + 1);

        if(topic === 'chat') {
            onNewMessageListener(message, participantId);
        } else if(topic === 'player') {
            onPlayerDataListener(message);
        } else if(topic === 'publish' && message === this.sessionId && !this.isLocalParticipantPublished) {
            this.localParticipant.publish();
            this.isLocalParticipantPublished = true;

            this.participants.forEach((participant) => {
                if(this.serviceMode === SERVICE_MODES.SFU && !participant.stream &&
                    (participant.streamConstraints.audio || participant.streamConstraints.video)) {
                    participant.publishRemote();
                }
            });
        }
    }

    onSyncData(params) {
        const {data, participantId} = params;
        onSyncDataListener({data, participantId});
    }

    sendNotification(notification) {
        this.signalingServer.sendNotification(notification);
    }

    kickParticipant({participantId, message}) {
        this.signalingServer.kickParticipant({participantId, message});
    }

    onParticipantSubscribedOnMediaStream(params) {
        const {participant} = params;
        const selectedParticipant = this.participants.find(p => p.participantId === participant.participantId);

        if(selectedParticipant) {
            selectedParticipant.updateUserType(participant.isViewer, participant.isBroadcaster, participant.isSynchronizer);
        }
    }

    close() {
        this.signalingServer.close(true);
        this.statistic.stopCollecting();
    }
}

export default Session;
