import {connect, connectWithoutPublish, connectAsAViewer, connectWithSharingScreen} from './connect';
import {disconnect} from './disconnect';
import enableStats from './enableStats';
import messages from './sendMessage';
/**
 * @namespace Session
 * @property {module:connect} Connect Connect
 * @property {module:disconnect} Disconnect Disconnect
 * @property {module:sendMessage} sendMessage sendMessage
 */

export default {
    connect,
    connectWithoutPublish,
    connectAsAViewer,
    connectWithSharingScreen,
    disconnect,
    enableStats,
    sendPlayerData: messages.sendPlayerData,
    sendMessage: messages.sendMessage,
};
