/**
 * @module onParticipantLeft
 */
let callback  = null;

/**
 *
 * @param cb
 */
export const onParticipantLeft = (cb) => {
    if(cb instanceof Function) {
        callback = cb;
    }
};

/**
 *
 * @param participantId
 */
export const onParticipantLeftListener = (participantId) => {
    if(callback) {
        callback({participantId});
    }
};
