import {onDisconnected} from './onDisconnected';
import onConnected from './onConnected';
import {onMosReport} from './onMosReport';
import onStreamCreated from './onStreamCreated';
import onLocalStreamCreated from './onLocalStreamCreated';
import {onMessageReceived, onPlayerData, onSyncData} from './onNewMessage';

/**
 * @namespace SessionListeners
 * @property {module:onConnected} OnConnected On connected
 * @property {module:onDisconnected} onDisconnected onDisconnected
 * @property {module:onLocalStreamChanged} onLocalStreamChanged onLocalStreamChanged
 * @property {module:onMosReport} onMosReport onMosReport
 * @property {module:onNetworkError} onNetworkError onNetworkError
 * @property {module:onNewMessage} onNewMessage onNewMessage
 * @property {module:onStreamCreated} pmStreamCreated pmStreamCreated
 */

export default {
	onDisconnected,
	onConnected,
	onStreamCreated,
	onLocalStreamCreated,
	onMosReport,
	onMessageReceived,
	onPlayerData,
	onSyncData
};
