/**
 * @module onParticipantMediaStreamChanged
 */
import { MediaStates, MediaTypes } from '../../constants/media';
import WTSession from '../../entities/WTSession/WTSession';

let callbackQueue = [];

/**
 *
 * @param cb
 */
const onParticipantMediaStreamChanged = (cb) => {
    if(cb instanceof Function) {
        callbackQueue.push(cb);
    }
};
/**
 *
 * @param participantId
 * @param mediaType
 * @param mediaState
 */
export const onParticipantMediaStreamChangedListener = (participantId, mediaType, mediaState) => {
    if(mediaType === MediaTypes.VIDEO && mediaState === MediaStates.ENABLED) {
        const participantWithUpdatedState = WTSession.getSession().participants.find(p => p.participantId === participantId);
        if(participantWithUpdatedState.originalTrack) {
            // restore original track instead of fake track
            const trackForRemoval = participantWithUpdatedState.stream.getVideoTracks()[0];
            participantWithUpdatedState.stream.removeTrack(trackForRemoval);
            participantWithUpdatedState.stream.addTrack(participantWithUpdatedState.originalTrack);
            delete participantWithUpdatedState.originalTrack;
        }
    }
    if(callbackQueue.length) {
        callbackQueue.forEach((callback) => {
            callback({participantId, mediaType, mediaState});
        });
    }
};

export default onParticipantMediaStreamChanged;
