/**
 * @module sendMessage
 */
import WTSession from '../../entities/WTSession/WTSession';

const sendNotification = (message) => {
    const session = WTSession.getSession();

    if (session && message) {
        session.sendNotification(message);
    }
};

export default {
    /**
     *
     * @param message
     * @returns {*|void}
     */
    sendMessage: (message) => message && sendNotification(`chat_${message}`),
    /**
     *
     * @param message
     * @returns {*|void}
     */
    sendPlayerData: (message) => message && sendNotification(`player_${message}`)
};
