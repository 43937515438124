import WTSession from '../../entities/WTSession/WTSession';

/**
 * @module remoteParticipantControl
 */

/**
 * @member checkParticipant
 * @param participantId
 * @returns {boolean}
 */
const checkParticipant = (participantId) => {
    const session = WTSession.getSession();
    const participants = session.getParticipants();

    if(participants) {
        const selectedParticipant = participants.find(p => p.participantId === participantId);

        if(!selectedParticipant) {
            console.warn('Invalid participant participantId');
            return false;
        }

        return true;
    }
};
/**
 *
 * @param participantId
 */
const publishRemoteParticipant = (participantId) => {
   if(checkParticipant(participantId)) {
       const session = WTSession.getSession();
       session.sendNotification(`publish_${participantId}`);
   }
};

/**
 *
 * @param participantId
 *
 * @param message
 */
const disconnectRemoteParticipant = (participantId, message) => {
    if(checkParticipant(participantId)) {
        const session = WTSession.getSession();
        session.kickParticipant({participantId, message});
    }
};

export {
    publishRemoteParticipant,
    disconnectRemoteParticipant
};
