import {EXCEPTION_CODES} from '../../constants/ExceptionCodes';

function MediaException(code = '', message='') {
    this.message = message;
    this.code = EXCEPTION_CODES[code];
}

export {
    MediaException,
};
