let onDeviceListUpdatesCB = null;

const onDeviceListUpdates = (cb) => {
    console.log(' on device list updates');
    // if (cb instanceof Function) {
    //     onDeviceListUpdatesCB = cb;
    // }
};

// const filterDevices = (devices) => {
//     return devices.reduce((acc, device) => {
//
//         if (device.kind === 'videoinput') {
//             acc.camera.push({
//                 value: device.deviceId,
//                 label: device.label ? device.label : `Video Input ${acc.camera.length + 1}`,
//             });
//         }
//
//         if (device.kind === 'audioinput') {
//             acc.microphone.push({
//                 value: device.deviceId,
//                 label: device.label ? device.label : `Audio Input ${acc.microphone.length + 1}`,
//             });
//         }
//
//         if (device.kind === 'audiooutput') {
//             acc.speaker.push({
//                 value: device.deviceId,
//                 label: device.label ? device.label : `Audio Output ${acc.speaker.length + 1}`,
//             });
//         }
//
//         return acc;
//     }, {
//         camera: [],
//         microphone: [],
//         speaker: [],
//     });
// };

// navigator.mediaDevices.ondevicechange = function (event) {
//     // if (onDeviceListUpdatesCB) {
//     //     navigator.mediaDevices.enumerateDevices().then(filterDevices).then((devices) => {
//     //         onDeviceListUpdatesCB(devices);
//     //     });
//     // }
// };

const getMediaDevices = () => {
console.log(" get media devices ");
    // if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    //     console.error('Can not get media devices.');
    //     return;
    // }
    //
    // return new Promise((resolve, reject) => {
    //     try {
    //         navigator.mediaDevices.enumerateDevices().then(filterDevices).then((devices) => {
    //             resolve(devices);
    //         });
    //     } catch (error) {
    //         console.error('[GetMediaDevices] error', error);
    //     }
    // });
};


export {
    getMediaDevices,
    onDeviceListUpdates
};
