import {MediaException} from '../api/ErrorListeners/Exceptions';

export const openMediaDevices = async (constraints) => {
    if(!!constraints.video)
        try {
            await navigator.mediaDevices.getUserMedia({video: false, audio: true});
        }catch (error){
            throw new MediaException('USER_AUDIO_PERMISSION_DENIED_ERROR_CODE', 'Could not get audio permission!');
        }
    if(!!constraints.video)
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        }catch(error){
            throw new MediaException('USER_VIDEO_PERMISSION_DENIED_ERROR_CODE', 'Could not get video permission!');
        }

    return await navigator.mediaDevices.getUserMedia(constraints);
};
