/**
 * @module onDisconnected
 */
let callback = null;

/**
 *
 * @param cb
 */
export const onDisconnected = (cb) => {
  if(cb instanceof Function) {
    callback = cb;
  }
};

/**
 *
 */
export const onDisconnectedListener = () => {
  if(callback) {
    callback();
  }
};
