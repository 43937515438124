import {onLocalParticipantReconnected, onLocalParticipantReconnecting, onLocalConnectionLost, onLocalConnectionResumed} from './localParticipant';
import {onRemoteParticipantReconnected, onRemoteParticipantReconnecting, onRemoteParticipantConnectionLost} from './remoteParticipant';

export default {
    onLocalParticipantReconnected,
    onLocalParticipantReconnecting,
    onLocalConnectionLost,
    onLocalConnectionResumed,
    onRemoteParticipantReconnected,
    onRemoteParticipantReconnecting,
    onRemoteParticipantConnectionLost
};
