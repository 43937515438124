let callback = null;
let callbackReconnected = null;
let callbackConnectionLost = null;

const onRemoteParticipantReconnecting = (cb) => {
    if(cb instanceof Function) {
        callback = cb;
    }
};

const onRemoteParticipantReconnected = (cb) => {
    if(cb instanceof Function) {
        callbackReconnected = cb;
    }
};

const onRemoteParticipantConnectionLost = (cb) => {
    if(cb instanceof Function) {
        callbackConnectionLost = cb;
    }
};

export const onRemoteParticipantConnectionLostListener = (participantId) => {
    if(callbackConnectionLost) {
        callbackConnectionLost(participantId);
    }
};

export const onRemoteParticipantReconnectingListener = (participantId) => {
    if(callback) {
        callback(participantId);
    }
};

export const onRemoteParticipantReconnectedListener = (participantId) => {
    if(callbackReconnected) {
        callbackReconnected(participantId);
    }
};

export { onRemoteParticipantReconnected, onRemoteParticipantReconnecting, onRemoteParticipantConnectionLost };
