import {MediaException} from '../ErrorListeners/Exceptions';


let audioConstraint = false;
let videoConstraint = false;
let stream = new MediaStream();

const doPreview = async (constraints) => {
    if(!!constraints.audio){
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        }catch(error){
            throw new MediaException('USER_AUDIO_PERMISSION_DENIED_ERROR_CODE', 'Could not get audio permission!');
        }
    }
    if(!!constraints.video){
        try {
            await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        }catch(error){
            throw new MediaException('USER_VIDEO_PERMISSION_DENIED_ERROR_CODE', 'Could not get video permission!');
        }
    }
    stream.getTracks().forEach(track => track && track.stop());
    stream = await navigator.mediaDevices.getUserMedia(constraints);
    return stream;
};

const setAudioConstraints = (constraint) => {
    audioConstraint = constraint;
};

const setVideoConstraints = (constraint) => {
    videoConstraint = constraint;
};

const getAudioConstraint = () => {
    return audioConstraint;
};

const getVideoConstraint = () => {
    return videoConstraint;
};

export  { doPreview, setAudioConstraints, setVideoConstraints, getAudioConstraint, getVideoConstraint};
