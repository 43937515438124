/**
 * @module onMosReport
 */
const callbackQueue = [];

/**
 *
 * @param cb
 */
export const onMosReport = (cb) => {
	if(cb instanceof Function) {
		callbackQueue.push(cb);
	}
};

/**
 *
 * @param data
 */
export const onMosReportListener = (data) => {
	if(callbackQueue.length) {
		callbackQueue.forEach(cb => cb(data));
	}
};
