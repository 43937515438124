'use strict';

var BrowserWebSocket = global.WebSocket || global.MozWebSocket;

var Logger = console;

/**
 * Get either the `WebSocket` or `MozWebSocket` globals
 * in the browser or try to resolve WebSocket-compatible
 * interface exposed by `ws` for Node-like environment.
 */

var WebSocket = BrowserWebSocket;
if (!WebSocket && typeof window === 'undefined') {
  try {
    WebSocket = require('ws');
  } catch (e) { }
}

//var SockJS = require('sockjs-client');

var MAX_RETRIES = 2000; // Forever...
var RETRY_TIME_MS = 3000; // FIXME: Implement exponential wait times...

var CONNECTING = 0;
var OPEN = 1;
var CLOSING = 2;
var CLOSED = 3;

/*
config = {
		uri : wsUri,
		useSockJS : true (use SockJS) / false (use WebSocket) by default,
		onconnected : callback method to invoke when connection is successful,
		ondisconnect : callback method to invoke when the connection is lost,
		onreconnecting : callback method to invoke when the client is reconnecting,
		onreconnected : callback method to invoke when the client succesfully reconnects,
	};
*/
function WebSocketWithReconnection(config) {

  var closing = false;
  var registerMessageHandler;
  var wsUri = config.uri;
  var useSockJS = config.useSockJS;
  var reconnecting = false;

  var forcingDisconnection = false;

  var ws;

  if (useSockJS) {
    ws = new SockJS(wsUri);
  } else {
    ws = new WebSocket(wsUri);
  }

  ws.onopen = function () {
    logConnected(ws, wsUri);
    if (config.onconnected) {
      config.onconnected();
    }
  };

  ws.onerror = function (error) {
    Logger.error('Could not connect to ' + wsUri + ' (invoking onerror if defined)', error);
    if (config.onerror) {
      config.onerror(error);
    }
  };

  function logConnected(ws, wsUri) {
    try {
      Logger.debug('WebSocket connected to ' + wsUri);
    } catch (e) {
      Logger.error(e);
    }
  }

  var reconnectionOnClose = function (e) {
    if (ws.readyState === CLOSED) {
      if (closing) {
        if(config.ondisconnect) {
          config.ondisconnect();
        }
        Logger.debug('Connection closed by user');
      } else if (e && e.code === 4000){
        Logger.debug('Connection closed, user was kicked');
      } else {
        Logger.debug('Connection closed unexpectecly. Reconnecting...');
        reconnectToSameUri(MAX_RETRIES, 1);
      }
    } else {
      Logger.debug('Close callback from previous websocket. Ignoring it');
    }
  };

  ws.onclose = reconnectionOnClose;

  function reconnectToSameUri(maxRetries, numRetries) {
    Logger.debug('reconnectToSameUri (attempt #' + numRetries + ', max=' + maxRetries + ')');

    if (numRetries === 1) {
      if (reconnecting) {
        Logger.warn('Trying to reconnectToNewUri when reconnecting... Ignoring this reconnection.');
        return;
      } else {
        reconnecting = true;
      }

      if (config.onreconnecting) {
        config.onreconnecting();
      }
    }

    if (forcingDisconnection) {
      reconnectToNewUri(maxRetries, numRetries, wsUri);

    } else {
      if (config.newWsUriOnReconnection) {
        config.newWsUriOnReconnection(function (error, newWsUri) {

          if (error) {
            Logger.debug(error);
            setTimeout(function () {
              reconnectToSameUri(maxRetries, numRetries + 1);
            }, RETRY_TIME_MS);
          } else {
            reconnectToNewUri(maxRetries, numRetries, newWsUri);
          }
        });
      } else {
        reconnectToNewUri(maxRetries, numRetries, wsUri);
      }
    }
  }

  // TODO Test retries. How to force not connection?
  function reconnectToNewUri(maxRetries, numRetries, reconnectWsUri) {
    Logger.debug('Reconnection attempt #' + numRetries);

    ws.close();

    wsUri = reconnectWsUri || wsUri;

    var newWs;
    if (useSockJS) {
      newWs = new SockJS(wsUri);
    } else {
      newWs = new WebSocket(wsUri);
    }

    newWs.onopen = function () {
      Logger.debug('Reconnected after ' + numRetries + ' attempts...');

      logConnected(newWs, wsUri);
      reconnecting = false;
      registerMessageHandler();
      if (config.onreconnected()) {
        config.onreconnected();
      }

      newWs.onclose = reconnectionOnClose;
    };

    var onErrorOrClose = function (error) {
      Logger.warn('Reconnection error: ', error);

      if (numRetries === maxRetries) {
        if (config.ondisconnect) {
          config.ondisconnect();
        }
      } else {
        setTimeout(function () {
          reconnectToSameUri(maxRetries, numRetries + 1);
        }, RETRY_TIME_MS);
      }
    };

    newWs.onerror = onErrorOrClose;

    ws = newWs;
  }

  this.close = function () {
    closing = true;
    ws.close();
  };


  // This method is only for testing
  this.forceClose = function (millis) {
    Logger.debug('Testing: Force WebSocket close');

    if (millis) {
      Logger.debug('Testing: Change wsUri for ' + millis + ' millis to simulate net failure');
      var goodWsUri = wsUri;
      wsUri = 'wss://21.234.12.34.4:443/';

      forcingDisconnection = true;

      setTimeout(function () {
        Logger.debug('Testing: Recover good wsUri ' + goodWsUri);
        wsUri = goodWsUri;

        forcingDisconnection = false;

      }, millis);
    }

    ws.close();
  };

  this.reconnectWs = function () {
    Logger.debug('reconnectWs');
    reconnectToSameUri(MAX_RETRIES, 1, wsUri);
  };

  this.send = function (message) {
    ws.send(message);
  };

  this.isClosed = function () {
    return closing;
  };

  this.setClosed = function () {
    closing = true;
  };

  this.addEventListener = function (type, callback) {
    registerMessageHandler = function () {
      ws.addEventListener(type, callback);
    };

    registerMessageHandler();
  };
}

export default WebSocketWithReconnection;
