/**
 * @module onParticipantJoined
 */
let callback = null;

/**
 *
 * @param cb
 */
const onParticipantJoin = (cb) => {
  callback = cb;
};
/**
 *
 * @param participant
 */
export const onParticipantJoinedListener = (participant) => {
  if(callback) {
    callback(participant);
  }
};

export default onParticipantJoin;
